<template>
  <b-row>
    <b-col md="12" class="table-responsive">
      <dataset
        v-slot="{ ds }"
        :ds-data="data"
        :ds-sortby="sortBy"
        :ds-sort-as="{}"
        :ds-search-in="fieldsSearch"
        :ds-search-as="{}"
      >
        <div class="row" :data-page-count="ds.dsPagecount">
          <div class="col-md-1 mb-2 mb-md-0">
            <dataset-show />
          </div>
          <slot name="patient_type" />
          <div class="col-3"></div>
          <div class="col-md-6">
            <dataset-search ds-search-placeholder="Buscar..." />
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped d-md-table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th
                      v-for="(th, index) in columns"
                      :key="th.field"
                      :class="['sort', th.sort]"
                      @click="order($event, index)"
                    >
                      {{ th.name }}
                      <i class="gg-select float-right"></i>
                    </th>
                  </tr>
                </thead>
                <dataset-item tag="tbody">
                  <template #default="{ row, rowIndex }">
                    <slot name="rows" v-bind:row="row" v-bind:rowIndex="rowIndex"></slot>
                  </template>
                </dataset-item>
              </table>
            </div>
          </div>
        </div>
        <div
          class="d-flex flex-md-row flex-column justify-content-between align-items-center"
        >
          <dataset-info class="mb-2 mb-md-0" />
          <dataset-pager />
        </div>
      </dataset>
    </b-col>
  </b-row>
</template>

<script>
import {
  Dataset,
  DatasetItem,
  DatasetInfo,
  DatasetPager,
  DatasetSearch,
  DatasetShow
} from 'vue-dataset'

export default {
  components: {
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    DatasetSearch,
    DatasetShow
  },
  props: {
    data: {
      type: Array,
      required: true
    },
    columns: {
      type: Array,
      required: true
    },
    fieldsSearch: {
      type: Array,
      required: true
    },
    patients: {
      type: Boolean,
      required: false
    }
  },
  mounted () {},
  computed: {
    sortBy () {
      return this.columns.reduce((acc, o) => {
        if (o.sort) {
          o.sort === 'asc' ? acc.push(o.field) : acc.push('-' + o.field)
        }

        return acc
      }, [])
    }
  },
  methods: {
    order (event, i) {
      let toset
      const sortEl = this.columns[i]

      if (!event.shiftKey) {
        this.columns.forEach((o) => {
          if (o.field !== sortEl.field) {
            o.sort = ''
          }
        })
      }
      if (!sortEl.sort) {
        toset = 'asc'
      }
      if (sortEl.sort === 'desc') {
        toset = event.shiftKey ? '' : 'asc'
      }
      if (sortEl.sort === 'asc') {
        toset = 'desc'
      }
      sortEl.sort = toset
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss" scoped>
.gg-select {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(1);
  width: 22px;
  height: 22px;
}

.gg-select::after,
.gg-select::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 7px;
  transform: rotate(-45deg);
}

.gg-select::before {
  border-left: 2px solid;
  border-bottom: 2px solid;
  bottom: 4px;
  opacity: 0.3;
}

.gg-select::after {
  border-right: 2px solid;
  border-top: 2px solid;
  top: 4px;
  opacity: 0.3;
}

th.sort {
  cursor: pointer;
  user-select: none;

  &.asc {
    .gg-select::after {
      opacity: 1;
    }
  }

  &.desc {
    .gg-select::before {
      opacity: 1;
    }
  }
}
</style>
